import React, { useState } from 'react';
import type { WCmsRsvpPageView } from '@zola/svc-web-api-ts-client';

import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import GuestSearchForm from 'components/publicWebsiteV2/pages/Rsvp/components/GuestSearchForm';
import GuestRsvpForm from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm';
import GuestRsvpSummary from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpSummary';

import { useAppSelector } from 'reducers/useAppSelector';
import {
  getGuestRsvpAccessForm,
  getGuestGroupRsvp,
  getRsvpShowSummary,
} from 'selectors/public/publicWebsiteSelectors';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { Container } from './Rsvp.styles';

type RsvpProps = {
  pageData?: WCmsRsvpPageView;
};

const Rsvp = ({ pageData }: RsvpProps): JSX.Element => {
  const { title, description, header_image_url, events, images } = pageData || {};
  const showAccessForm = useAppSelector(getGuestRsvpAccessForm);
  const showSummary = useAppSelector(getRsvpShowSummary);
  const guestGroupRsvp = useAppSelector(getGuestGroupRsvp);
  const {
    state: { inPreview },
  } = useWebsiteThemeContext();

  const [guestGroupUuid, setGuestGroupUuid] = useState(guestGroupRsvp?.uuid || '');

  const handleSelectGuestUuid = (uuid: string) => setGuestGroupUuid(uuid);

  const showHeader = !showAccessForm && !guestGroupRsvp.guest_group_uuid && !showSummary;

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  return (
    <Container>
      {showHeader && (
        <TitleImgDesc
          title={title}
          url={cmsHeaderImgUrl || header_image_url}
          description={description}
        />
      )}
      {!guestGroupRsvp.guest_group_uuid && !inPreview && (
        <GuestSearchForm onSelectGuestUuid={handleSelectGuestUuid} />
      )}
      {(guestGroupRsvp.guest_group_uuid || inPreview) && (
        <>
          {showSummary ? (
            <GuestRsvpSummary />
          ) : (
            <GuestRsvpForm
              guestGroupUuid={guestGroupUuid}
              previewEvents={inPreview ? events : undefined}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Rsvp;
